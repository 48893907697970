import { nanoid } from 'nanoid';
import ChatSpaceGif from '../images/ChatSpace.gif';
import TwiddaGif from '../images/Twidda.gif';
import StarWarsGif from '../images/Star_Wars_2.gif';
import TradePenguinGif from '../images/TradePenguin.gif';

// HEAD DATA
export const headData = {
  title: 'Crystal Word | Developer', // e.g: 'Name | Developer'
  // lang: '',  e.g: en, es, fr, jp
  description: 'Welcome to my porfolio.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Crystal Word.',
  subtitle: 'I am a self-taught Software Developer.',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    " Whether it was restoring my Dad's old Windows computer, trying to fix my PSP after my brother spilled Sprite all over it or building my first PC from scratch, " +
    'solving technical problems has always been a huge interest for me. Initially, I mainly considered it a hobby, something to challenge myself mentally as much as sports pursuits challenged me ' +
    'physically. However, being exposed to it, more specifically to programming, in a professional setting quickly sparked a desire in me to more seriously pursue it. ' +
    'Starting off working on small HTML and CSS projects solidified my love for the problem solving process which only increased as I built my way up to more robust languages and tools. ',
  paragraphTwo:
    ' "Possibility", one of my favorite words, is a term that often comes to my mind when I\'m asked to describe why I love programming. The world we live in ' +
    'is a complex place with many complex problems and I love how programming gives seemingly endless avenues to address and solve those problems. ' +
    "Whether it's for a company or a client, I find joy and satisfaction in helping to solve them.",
  paragraphThree: '',
  resume: 'https://drive.google.com/file/d/1qVUwSDGsRZ7_VQE_IB1wAajnYXP3bHor/view?usp=sharing', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: TradePenguinGif,
    title: 'Trade Penguin',
    info:
      'Trade Penguin is a full-fledged CRUD application. It allows the user to register and sign in to a ' +
      'stock trading platform where they can buy, sell and manage stocks using fantasy money. ' +
      'The purpose of this project was to bring the CRUD skills I have been building all together into one application. ' +
      'During this project, I grew my knowledge of stocks in general while working with the CLOUD IEX API to pull up-to-date stock information.',

    info2:
      'I additionally challenged myself by implementing Flask SQL Alchemy to manage my database and React Google Charts to display all of the data. ' +
      'There was no shortage of bugs throughout, which gave me the opportunity to build on my problem-solving skills. ' +
      'Finding solutions helped to refine my understanding of the entire full stack process.',
    url: 'https://stock-app-9zdf.onrender.com/',
    repo: 'https://github.com/cheyjax116/stock_app', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: ChatSpaceGif,
    title: 'ChatSpace',
    info:
      'This application allows the user to register and sign in to a chat interface where they can view ' +
      'and exchange messages with other users. The purpose of this project ' +
      'was to build on my understanding of full stack development and connecting to a database. I learned about Python ' +
      'blueprints and their usefulness when creating connections between the databse and API endpoints.',
    info2:
      'I additionally challenged myself to add some security when storing user passwords which led me to research and implement a hashing method ' +
      'to encrypt each user password in the database. Overall, the bugs I ran into forced me to find different approaches to ' +
      'previous solutions which accelerated my learning and deepened my understanding of the whole process.',
    url: 'https://chatspace-app.onrender.com/',
    repo: 'https://github.com/cheyjax116/chat_app', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: TwiddaGif,
    title: 'Twidda',
    info:
      'This application allows the user to search recents tweets by subject or keyword and pull random tweets from specific profiles on Twitter. ' +
      'The purpose of this project was to grow accustomed to full stack development and separation of concerns. Building ' +
      'and connecting the Flask server taught me a lot about how data can be shared between the frontend and backend. ',
    info2:
      'Working with the Twitter API was a bit of a challenge but it allowed me to practice referencing documentation to formulate solutions to problems. ' +
      'I ran into many bugs especially during deployment, all of which helped me to get a better grasp on the full stack process, working with both internal and external APIs ' +
      'and the importance of separation of concerns.',
    url: 'https://twidda-app.onrender.com',
    repo: 'https://github.com/cheyjax116/twitter_showcase_app', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: StarWarsGif,
    title: 'Star Wars API',
    info:
      'This application allows the user to search a table of Star Wars character information. ' +
      'The purpose of this project was to build on my knowledge of React and Bootstrap and familiarize myself ' +
      'with fetching API data with Axios. I was also introduced to several React ' +
      'hooks including useEffect and useState as well the React-Paginate npm component.',
    info2:
      'In addition to including pagination, I challenged myself to allow live search functionality that enables the user ' +
      'to see real-time results as they type in their search term. I ran into several bugs during this project especially while ' +
      'implementing live search and pulling data from the SWAPI API. Solving those issues helped me to gain a deeper understanding ' +
      'of both and build on my problem solving skills.',
    url: 'https://star-wars-api-miy9.onrender.com/',
    repo: 'https://github.com/cheyjax116/star_wars_api', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'crysintech@outlook.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'http://www.linkedin.com/in/crystal-word-software-engineer',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/cheyjax116',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
